import React, {useState} from "react";
import {Form, message} from "antd";
import {useApp} from "../contexts/App";
import {postAuth} from "../services/app.service";

export default function Auth(props: any) {
    const {setUser} = useApp();

    const onLogin = async (values: { username: string, password: string }) => {
        postAuth(values).then(value => {
            if (value?.token) {
                setUser({
                    ...values,
                    token: value?.token
                });
            } else {
                message.error("Sai tên đăng nhập hoặc mật khẩu!");
            }
        })
    }


    return (
        <>
            <div className="d-flex flex-column flex-root">

                <div className="d-flex flex-column flex-lg-row flex-column-fluid" id="kt_login">

                    <div className="d-flex flex-column flex-lg-row-auto bg-primary w-lg-600px pt-15 pt-lg-0">

                        <div
                            className="d-flex flex-row-fluid flex-center flex-column-auto flex-column text-center mb-5">

                            <a href="/" className="mb-6 logo-text">

                            </a>


                            <h3 className="fw-bolder fs-2x text-white lh-lg"></h3>

                        </div>


                        <div style={{backgroundImage: "url('/assets/images/bgAuth.png')"}}
                             className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"/>

                    </div>


                    <div
                        className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-10 py-lg-20 px-10 p-lg-7 mx-auto mw-450px w-100">

                        <div className="d-flex flex-column-fluid flex-center py-10">
                            <SignIn onSubmit={onLogin}/>
                        </div>
                        <div
                            className="d-flex justify-content-lg-start justify-content-center align-items-center py-2 py-lg-7 py-lg-0 fs-5 fw-bolder">
                            <a href="#"
                               className="text-gray-600 text-hover-primary">Thông tin</a>
                            <a href="#"
                               className="text-gray-600 text-hover-primary ms-10">Hỗ trợ</a>
                            <a href="#"
                               className="text-gray-600 text-hover-primary ms-10">Liên hệ</a>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

function SignIn(props: any) {
    const [form] = Form.useForm();

    const onSubmit = () => {
        props.onSubmit(form.getFieldsValue());
    }

    return (
        <Form form={form}>
            <div className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                 id="kt_login_signin_form">

                <div className="pb-5 pb-lg-15">
                    <h3 className="fw-bolder text-dark display-6">Hệ thống quản lý Etsy</h3>
                    {/*<div className="text-muted fw-bold fs-3">New Here?*/}
                    {/*    <a href="#" className="text-primary fw-bolder"*/}
                    {/*       id="kt_login_signin_form_singup_button">Create Index</a></div>*/}
                </div>


                <div className="fv-row mb-10 fv-plugins-icon-container">
                    <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                    <Form.Item name={'username'}>
                        <input className="form-control form-control-lg form-control-solid" type="text"
                               name="username" autoComplete="off"/>
                    </Form.Item>
                    <div className="fv-plugins-message-container invalid-feedback"/>
                </div>


                <div className="fv-row mb-10 fv-plugins-icon-container">
                    <div className="d-flex justify-content-between mt-n5">
                        <label className="form-label fs-6 fw-bolder text-dark pt-5">Mật khẩu</label>
                        {/*<a href="#" className="text-primary fs-6 fw-bolder text-hover-primary pt-5"*/}
                        {/*   id="kt_login_signin_form_password_reset_button">Forgot Password ?</a>*/}
                    </div>
                    <Form.Item name={'password'}>
                        <input onKeyPress={event => {
                            if (event.key.toLocaleLowerCase() === 'enter') {
                                onSubmit();
                            }
                        }} className="form-control form-control-lg form-control-solid" type="password"
                               name="password" autoComplete="off"/>
                    </Form.Item>
                    <div className="fv-plugins-message-container invalid-feedback"/>
                </div>


                <div className="pb-lg-0 pb-5">
                    <button type="button" onClick={() => onSubmit()} id="kt_login_signin_form_submit_button"
                            className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3">Đăng nhập
                    </button>
                </div>

                <input type="hidden"/>
                <div/>
            </div>
        </Form>
    )
}

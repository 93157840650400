import React, {useEffect, useState} from "react";

const App = React.createContext<{
    user?: any,
    setUser?: any,
}>({});

export const AppProvider = ({children}: any) => {
    const currentUser = localStorage.getItem('app-user') ? JSON.parse(localStorage.getItem('app-user') || "") : null;
    if (currentUser) {
        // @ts-ignore
        window.user = currentUser;
    }
    const [user, setUser] = useState(currentUser);

    useEffect(() => {
        if (user) {
            // @ts-ignore
            window.user = user;
            localStorage.setItem('app-user', JSON.stringify(user));
        }
    }, [user])


    return (
        <App.Provider
            value={{
                user,
                setUser,
            }}>
            {children}
        </App.Provider>
    )
}

export const useApp = () => React.useContext(App);
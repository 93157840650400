import Environment from "../environment";
import HttpService from "./http.service";

export async function postAuth(user: any) {
    return HttpService.post(`${Environment.host}/auth`, user);
}


export async function getOrders(page: number = 0) {
    return HttpService.get(`${Environment.host}/order`, {page});
}

export async function getAccounts(page: number = 0) {
    return HttpService.get(`${Environment.host}/account`, {page});
}

export async function postAccount(data: any) {
    return HttpService.post(`${Environment.host}/account`, data);
}

export async function putAccount(id: string, data: any) {
    return HttpService.put(`${Environment.host}/account/${id}`, data);
}

export async function postHistory(history: any) {
    return HttpService.post(`${Environment.host}/history/`, history);
}

import React, {useEffect} from 'react';
import './App.css';
import Main from "./pages/Main";
import {AppProvider} from "./contexts/App";

export default function App() {

    return (
        <AppProvider>
            <Main/>
        </AppProvider>
    );
}
import React, {useState} from "react";
import {NavLink} from "react-router-dom";

export default function (props: any) {
    return (
        <div id="kt_aside" className="aside bg-info">

            <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">

                <div className="aside-logo d-flex flex-column align-items-center flex-column-auto py-4 pt-lg-10 pb-lg-7"
                     id="kt_aside_logo">
                    <a href="#">
                        <img alt="Logo"
                             src="https://preview.keenthemes.com/start-html-pro/assets/media/logos/logo-compact.svg"
                             className="mh-50px"/>
                    </a>
                </div>


                <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid pt-0 pb-5"
                     id="kt_aside_nav">

                    <div className="hover-scroll-y">

                        <ul className="nav flex-column">

                            {/*<li className="nav-item mb-1">*/}
                            {/*    <NavLink to="/users"*/}
                            {/*             className={"nav-link h-40px w-40px h-lg-50px w-lg-50px btn btn-custom btn-icon btn-color-white"}>*/}

                            {/*        <span className="svg-icon svg-icon-2 svg-icon-lg-1"><svg*/}
                            {/*            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"*/}
                            {/*            fill="none">*/}
                            {/*            <path*/}
                            {/*                d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"*/}
                            {/*                fill="black"/>*/}
                            {/*            <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="black"/>*/}
                            {/*            </svg>*/}
                            {/*        </span>*/}
                            {/*        <span>Người dùng</span>*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}

                            <li className="nav-item mb-1">
                                <NavLink to="/accounts"
                                         className={"nav-link h-40px w-40px h-lg-50px w-lg-50px btn btn-custom btn-icon btn-color-white"}>

                                    <span className="svg-icon svg-icon-2 svg-icon-lg-1">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none">
													<path opacity="0.3"
                                                          d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z"
                                                          fill="black"></path>
													<path
                                                        d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z"
                                                        fill="black"></path>
												</svg>
											</span>
                                    <span>Tài khoản</span>
                                </NavLink>
                            </li>


                            <li className="nav-item mb-1">
                                <NavLink to="/orders"
                                         className={"nav-link h-40px w-40px h-lg-50px w-lg-50px btn btn-custom btn-icon btn-color-white"}>

                                    <span className="svg-icon svg-icon-2 svg-icon-lg-1">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none">
													<path opacity="0.3"
                                                          d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                                          fill="black"></path>
													<path
                                                        d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                                        fill="black"></path>
												</svg>
											</span>
                                    <span>Đơn hàng</span>
                                </NavLink>
                            </li>

                        </ul>

                    </div>

                </div>


                <div  className="aside-footer d-flex flex-column align-items-center flex-column-auto py-5 py-lg-7"
                     id="kt_aside_footer">

                    <div className="mb-2" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover"
                         title="" data-bs-original-title="Quick settings">

                    </div>

                </div>

            </div>
        </div>
    )
}

import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Action from "../components/Action";
import {Popconfirm, Radio, Spin} from "antd";
import moment from "moment";
import {getOrders} from "../services/app.service";

export default function Order(props: any) {
    const [rows, setRows] = useState<Array<any>>([]);
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadData().then();
    }, [page]);

    const loadData = async () => {
        setLoading(true)
        const result = await getOrders(page);
        if (result) {
            setRows(result);
        }
    }


    const onRemove = (row: any) => {

    }


    return (
        <>
            <div className="card">

                <div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark">Quản lý đơn hàng</span>
                    </h3>
                    <div className="card-toolbar">
                        <a className="btn btn-primary fw-bolder fs-7">Cập nhật</a>
                    </div>
                </div>


                <div className="card-body">

                    <div className="table-responsive">
                        <table className="table align-middle border-gray-100" id="kt_advance_table_template_4">
                            <thead>
                            <tr className="text-start fw-bolder text-black text-uppercase fs-7 border-gray-100 border-bottom-1">
                                <td className="py-5">Mã đơn</td>
                                <td className="py-5">Sản phẩm</td>
                                <td className="py-5">Khách hàng</td>
                                <td className="py-5">SL</td>
                                <td className="py-5">Đơn giá</td>
                                <td className="py-5">Ship</td>
                                <td className="py-5">Ngày tạo</td>
                                <td className="pe-0 text-end py-5"/>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                rows.map((item, index) => (
                                    <tr key={index.toString()}>
                                        <td>
                                            <div>{item.orderId} <span
                                                className={'account-view'}>{item?.account?.name || ""}</span></div>
                                        </td>
                                        <td>
                                            {item?.products.map((product: any, indexProduct: number) => {
                                                return (
                                                    <div key={indexProduct} className={'product-view'}>
                                                        <img src={product?.image}/>
                                                        <div>
                                                            <h3>{product?.title}</h3>
                                                            <ul>
                                                                <li><b>Sku:</b> {product?.sku}
                                                                    <b>Qty:</b> {product?.quantity}</li>
                                                                <li dangerouslySetInnerHTML={{__html: (product?.variations || []).map((v: any) => `<b>${v.property}:</b> ${v.value}`).join(' ')}}/>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </td>
                                        <td>
                                            <ul className={'customer-view'}>
                                                <li><b>Name:</b> {item?.shipAddress?.name}</li>
                                                <li><b>Line:</b> {item?.shipAddress?.first_line}</li>
                                                <li><b>City:</b> {item?.shipAddress?.city}
                                                    <b>State:</b> {item?.shipAddress?.state}</li>
                                                <li><b>Zip:</b> {item?.shipAddress?.zip}
                                                    <b>Country:</b> {item?.shipAddress?.country}</li>

                                            </ul>
                                        </td>
                                        <td>{item.quantity}</td>
                                        <td>{item.total}$</td>
                                        <td>
                                            <ul className={'ship-view'}>
                                                <li><b>Hạn ship:</b> {moment(item.shipDate).format('DD/MM/YYYY')}</li>
                                                <li><b>Loại:</b> {item?.shipMethod}</li>
                                                <li><b>Giá:</b> {item?.shipPrice}$</li>
                                            </ul>

                                        </td>
                                        <td>
                                            {moment(item.orderDate).format('DD/MM/YYYY')}
                                            <div className={`status-view ${item.status}`}>
                                                <b>
                                                    {item.status === 'new' ? 'Đơn mới' : ''}
                                                    {item.status === 'complete' ? 'Hoàn thành' : ''}
                                                </b>
                                            </div>
                                        </td>
                                        <td className="pe-0 text-end">
                                            <Popconfirm onConfirm={() => onRemove(item)} title={'Confirm delete?'}>
                                                <Action type={'remove'}/>
                                            </Popconfirm>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </>
    )
}

import axios from 'axios';


const headers: any = async () => {
    const win: any = window;
    return {
        'user-token': win?.user?.token,
    };
};

export default class HttpService {
    static get(url: string, params: any = {}, hasHeaders: boolean = true): Promise<any> {
        return new Promise(async (resolve, reject) => {
            axios.get(url, {
                headers: hasHeaders ? (await headers()) : {},
                params: params,
            }).then((value) => {
                resolve(value?.data || null);
            }).catch((error) => {
                resolve(null);
            });
        });
    }

    static delete(url: string, params: any = {}, hasHeaders: boolean = true): Promise<any> {
        return new Promise(async (resolve, reject) => {
            axios.delete(url, {
                headers: hasHeaders ? (await headers()) : {},
                params,
            }).then(value => {
                resolve(value?.data || null);
            }).catch(error => {
                resolve(null);
            });
        });
    }

    static post(url: string, data: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            axios.post(url, data, {
                headers: await headers(),
            }).then(value => {
                resolve(value?.data || null);
            }).catch(error => {
                resolve(null);
            });
        });
    }

    static put(url: string, data: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            axios.put(url, data, {
                headers: await headers(),
            }).then(value => {
                resolve(value?.data || null);
            }).catch(error => {
                resolve(null);
            });
        });
    }
}

import React from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Dashboard from "../pages/Dashboard";
import Navigation from "../components/Navigation";
import Header from "../components/Header";
import Account from "../pages/Account";
import Order from "../pages/Order";
import AccountEdit from "../pages/Account/edit";


const AppWrapper = (props: any) => {
    const headerConfig = {
        title: 'Etsy Manager',
        breadcrumb: [
            {title: 'Home', link: '/'},
            {title: 'Dashboard', link: null},
            {title: 'Widget management', link: '/widgets'},
        ]
    }

    return (
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">

            <Header headerConfig={headerConfig}/>

            <div className="d-flex flex-column flex-column-fluid">
                <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
                    <div className="container-xxl" style={{maxWidth: '100%'}}>
                        {props.children}
                    </div>
                </div>
            </div>


            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">

                <div style={{maxWidth: '100%'}} className="container-xxl d-flex flex-column flex-md-row flex-stack">

                    <div className="text-dark order-2 order-md-1">
                        <span className="text-muted fw-bold me-2">2021©</span>
                        <a className="text-gray-800 text-hover-primary">Hệ thống Etsy</a>
                    </div>


                    <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                        <li className="menu-item">
                            <a target="_blank" className="menu-link px-2">Thông tin</a>
                        </li>
                        <li className="menu-item">
                            <a className="menu-link px-2">Hỗ trợ</a>
                        </li>
                        <li className="menu-item">
                            <a className="menu-link px-2">Liên hệ</a>
                        </li>
                    </ul>

                </div>

            </div>

        </div>
    )
}

export default function RouterApp() {
    return (
        <Router>
            <Navigation/>
            <AppWrapper>
                <Switch>
                    <Route exact path="/accounts">
                        <Account/>
                    </Route>
                    <Route exact path="/account/:id">
                        <AccountEdit/>
                    </Route>
                    <Route exact path="/account">
                        <AccountEdit/>
                    </Route>
                    <Route exact path="/orders">
                        <Order/>
                    </Route>
                </Switch>
            </AppWrapper>
        </Router>
    );
}
